import { graphql } from 'gatsby';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FlipMove from 'react-flip-move';
import { ElementTag } from 'src/components/ElementTags/ElementTags';
import { Cta, Hero, Partners } from 'src/components/LandingPage';
import NodeProjectCard from 'src/components/NodeProjectCard/NodeProjectCard';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';

/**
 * We may have categories that aren't assigned to any project,
 * thus we have to filter the categories.
 *
 * Sanity documents do not have internal information about
 * whether they have been referenced.
 */
function getCategoriesReferencedByProjects(projects) {
  const categories = projects.reduce((acc, curr) => [...acc, ...curr.category], []);

  return uniqBy(categories, 'id');
}

// eslint-disable-next-line react/prop-types
function ProjectListingPage({ data, location }) {
  const {
    sanityBasicPage: {
      references: { hero, partners, cta },
      title,
      pageSeo,
    },
    allSanityProject: { nodes: projects },
  } = data;

  const seo = {
    title: pageSeo?.title ?? title,
    description: pageSeo?.description,
    image: pageSeo?.image?.asset?.url,
    location,
  };

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const categories = [
    {
      name: 'Alles',
      color: 'primary',
      id: null,
    },
    ...getCategoriesReferencedByProjects(projects),
  ];
  function handleClick(e, id) {
    e.preventDefault();
    setSelectedCategoryId(id);
  }
  function getFilteredProjects(selectedCatId) {
    if (!selectedCatId) {
      const customSortOrder = [
        'Kiterjesztett valóság (AR I MR)', // 1st priority
        'iOS és Android applikációk', // 2nd priority
        'Unity alapú multiplatform applikációk', // 3rd priority
        'Érintőképernyős megoldások',
      ];

      const sortedProjects = projects.sort((a, b) => {
        const categoryA = a.category[0].name;
        const categoryB = b.category[0].name;

        return customSortOrder.indexOf(categoryA) - customSortOrder.indexOf(categoryB);
      });
      return sortedProjects;
    }

    const filteredProjects = projects.filter((project) => {
      const categoriesOfTheProject = project.category;
      return categoriesOfTheProject.filter((category) => category.id === selectedCatId).length > 0;
    });
    return filteredProjects;
  }

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <Container fluid className="bg-shapes-banner">
        <Hero hero={hero} />
        <Container className="pt-xl-6">
          <Row noGutters className="mx-n2">
            {categories.map((category) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Col key={category.id} xs="auto" className="px-2">
                <ElementTag
                  onClick={(e) => handleClick(e, category.id)}
                  isActive={selectedCategoryId === category.id}
                  color={category.color}
                  id={category.id}
                  name={category.name}
                  isButton
                />
              </Col>
            ))}
          </Row>
          <Row style={{ position: 'relative' }} className="mx-xl-n3">
            <FlipMove typeName={null}>
              {getFilteredProjects(selectedCategoryId).map((project) => (
                <Col key={project.id} xs={12} lg={4} className="px-xl-3 py-5">
                  <NodeProjectCard card={project} />
                </Col>
              ))}
            </FlipMove>
          </Row>
          {/* Todo: add load more button  */}
        </Container>
      </Container>
      {/* eslint-disable react/jsx-boolean-value */}
      <Partners partners={partners} isIndex={true} />
      <Cta cta={cta} />
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    sanityBasicPage(id: { eq: $id }) {
      title
      pageSeo {
        ...getPageSeo
      }
      references {
        hero {
          ...getHero
        }
        partners {
          ...getPartners
        }
        cta {
          ...getCta
        }
      }
    }

    allSanityProject(sort: { order: ASC, fields: order }) {
      nodes {
        ...getProjectAsCard
      }
    }
  }
`;

ProjectListingPage.propTypes = {
  data: PropTypes.shape({
    allSanityProject: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    sanityBasicPage: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default ProjectListingPage;
