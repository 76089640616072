import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ElementTags } from 'src/components/ElementTags/ElementTags';
import { useTheme } from 'src/hooks';
import imgFallback from 'src/utils/image';

function NodeProjectCard({ card }) {
  const { navigation, global } = useTheme();

  return (
    <Container fluid className="position-relative px-0">
      <Row noGutters className="animation-image-scale">
        <Col xs={12} className="mb-4">
          <GatsbyImage
            image={card.previewImage?.image?.asset?.gatsbyImageData ?? imgFallback}
            alt={card.previewImage?.alt ?? ''}
            className="shadow-image d-flex"
          />
        </Col>
        <Col xs={12} className="mb-2">
          <Link to={`/${navigation.projects.path}/${card.slug.current}`} className="stretched-link">
            <h3 className="h4 mb-0">{card.title}</h3>
          </Link>
        </Col>
        <Col xs={12} className="mb-3">
          <ElementTags category={card.category} />
        </Col>
        <Col xs={12} className="mb-5">
          <p className="text-muted mb-0">{card.description}</p>
        </Col>
        <Col xs={12}>
          <Link to={`/${navigation.projects.path}/${card.slug.current}`} className="btn-link-primary">
            {global.readMore}
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

NodeProjectCard.propTypes = {
  card: PropTypes.shape({}).isRequired,
};

export default NodeProjectCard;
