import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

/* eslint-disable react/prop-types, camelcase */
function ElementTag({ name, id, color, isButton, isActive, onClick }) {
  return isButton ? (
    <button key={id} type="button" onClick={onClick} className={`badge-${color} ${isActive ? 'active' : ''}`}>
      {name}
    </button>
  ) : (
    <div key={id} className={`badge-${color} text-uppercase px-1 ${isActive ? 'active' : ''}`}>
      {name}
    </div>
  );
}

function ElementTags({ category }) {
  return (
    <Row noGutters className="mx-n2">
      {category.map(({ name, id, color }) => (
        <Col key={id} xs="auto" className="px-2">
          <div className={`badge-${color} text-uppercase px-1`}>{name}</div>
        </Col>
      ))}
    </Row>
  );
}

ElementTags.propTypes = {
  category: PropTypes.arrayOf(PropTypes.shape({})),
};

ElementTags.defaultProps = {
  category: null,
};

export { ElementTags, ElementTag };
